import { IconButton, Input } from '@mui/joy'
import './style.css'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import { modelSelectionState, releaseBatchSelectionState, searchKeywordState } from './state-store'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { useState } from 'react'

const sx = {
  fontSize: 'smaller',
  paddingBlock: '6px',
}

export const ModelsInput = () => {

  const setReleaseModels = useSetRecoilState(modelSelectionState)

  const resetBatchReleaseSelection = useResetRecoilState(releaseBatchSelectionState)

  const resetSearchKeyword = useResetRecoilState(searchKeywordState)

  const [ input, setInput ] = useState('')

  const isValidModelType = () => !!input && input.match(/^[A-Z0-9]{6}$/)

  const errorInput = () => !!input && !isValidModelType()

  const handleModelTypeInput = (event) => {
    const newValue = event?.target?.value
    if (newValue?.length > 6) {
      return
    }
    setInput(newValue?.toUpperCase().trim() ?? '')
  }

  const handleAddModel = () => {
    if (isValidModelType(input)) {
      setReleaseModels(oldValue => [ ...oldValue, [ input ] ])
      resetBatchReleaseSelection()
      resetSearchKeyword()
      setInput('')
    }
  }

  return (
    <div className="models-input-panel" id="release-model-input-selector">
      <Input size="sm" sx={sx}
             value={input}
             error={errorInput()}
             onChange={handleModelTypeInput}
             onKeyDown={e => e.key === 'Enter' && handleAddModel()}
      />
      <IconButton className="add-release-model"
                  disabled={!isValidModelType(input)}
                  onClick={handleAddModel}>
        <AddCircleOutlineOutlinedIcon/>
      </IconButton></div>
  )
}
