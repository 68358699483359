import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { preSignUrl } from '../BffClient'
import { env } from '../env'
import { Options, usePreference } from '../profile/preferences'
import DownloadIcon from '@mui/icons-material/Download'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { AvailabilityTip } from '../styled/AvailabilityTip'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  height: '60px'
}))

const icon = status =>
  status ? <CheckCircleOutlineIcon color="success"/> : <CancelOutlinedIcon color="disabled"/>

export const ModelRow = ({ model }) => {

  const timeString = (timestamp) => {
    if (!timestamp) {
      return ''
    }
    const dateString = new Date(timestamp * 1 - new Date().getTimezoneOffset() * 60 * 1000).toISOString()
    return dateString.substring(0, 10) + ' ' + dateString.substring(11, 19)
  }

  const openPage = ({ bucket, key }) =>
    preSignUrl(bucket, key)
      .then(window.open)

  const openExtension = (source) => {
    const { market, language, modelType, modelYear } = model
    const bucket = `car-model-extension-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}/${source}`
    const confirm = window.confirm(`Open Extension ${source} S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPresentation = (file) => {
    const { market, language } = model
    const bucket = `market-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${file}`
    const confirm = window.confirm(`Open Presentation ${file} S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPccd = () => {
    const { market, language, modelType, modelYear } = model
    const bucket = `pccd-car-model-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open PCCD S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openDomainModel = () => {
    const { market, language, modelType, modelYear } = model
    const bucket = `car-models-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open Domain model for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPresentationModel = () => {
    const { market, language, modelType, modelYear } = model
    const bucket = `market-blue-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/models/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open Presentation Model S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const availabilityTips = model.availability().tips

  const [ showDomain ] = usePreference(Options.SHOW_DOMAIN_LAYER)

  return (
    <>
      <StyledTableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        className="table-row"
      >
        <TableCell align="center">{model.market}<br/>{model.language}</TableCell>
        <TableCell align="right">{model.modelName}</TableCell>
        <TableCell align="center">
          <div className="table-cell-model-type-year">
            {model.modelType}<br/>{model.modelYear}
            {availabilityTips.length > 0 &&
              <AvailabilityTip tips={availabilityTips}>
                < InfoOutlinedIcon className="table-cell-model-info"/>
              </AvailabilityTip>
            }
          </div>
        </TableCell>
        <TableCell align="center">{model.modelRange}</TableCell>
        <TableCell align="right">{model.modelSeries}</TableCell>
        <TableCell align="right">{model.bodyType}</TableCell>
        <TableCell align="center">{model.engineType}</TableCell>
        <TableCell align="right">{model.price}</TableCell>
        <TableCell align="right">{model.priceDisclaimers}</TableCell>
        <TableCell align="center">{icon(model.technicalDetail)}</TableCell>
        <TableCell align="center">{icon(model.economyDetail)}</TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openPccd()}>
          {timeString(model.pccdCreationTime)}
        </TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openExtension('ps5')}>
          {timeString(model.sitecorePushCreationTime)}
        </TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openExtension('sitecore')}>
          {timeString(model.sitecorePullCreationTime)}
        </TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openExtension('sitecore-pull')}>
          {timeString(model.singleModelCreationTime)}
        </TableCell>
        <TableCell align="center" hidden={!showDomain} className="clickable" onClick={openDomainModel}><DownloadIcon/></TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openPresentationModel()}>
          {timeString(model.ps5CreationTime)}
        </TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openPresentation('models')}>
          {timeString(model.modelListCreationTime)}
        </TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openPresentation('structure')}>
          {timeString(model.marketStructureCreationTime)}
        </TableCell>
        <TableCell align="right" className="table-cell-datetime clickable" onClick={() => openPresentation('eco-groups')}>
          {timeString(model.ecoGroupCreationTime)}
        </TableCell>
      </StyledTableRow>
    </>
  )

}
