import React from 'react'
import { FormHelperText, List, ListItem, Radio, radioClasses, RadioGroup } from '@mui/joy'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { modelSelectionState, releaseBatchSelectionState, searchKeywordState } from './state-store'
import { releases } from './releases'

export const ModelBatches = () => {

  const resetSearchKeyword = useResetRecoilState(searchKeywordState)

  const [ releaseSelection, setReleaseSelection ] = useRecoilState(releaseBatchSelectionState)

  const setModelSelection = useSetRecoilState(modelSelectionState)

  return (
    <RadioGroup aria-label="release-selector"
                name="release-selector"
                defaultValue=""
                sx={{
                  gap: 2,
                  [`& .${radioClasses.checked}`]: {
                    [`& .${radioClasses.action}`]: {
                      inset: -1,
                      border: '3px solid',
                      borderColor: 'primary.500',
                    },
                  },
                  [`& .${radioClasses.radio}`]: {
                    display: 'contents',
                    '& > svg': {
                      zIndex: 2,
                      position: 'absolute',
                      top: '-8px',
                      right: '-8px',
                      bgcolor: 'background.surface',
                      borderRadius: '50%',
                    },
                  },
                }}>
      <List
        id="release-batches-selector"
        sx={{
          width: '1',
          '--ListDivider-gap': '0',
          '--List-gap': '0.5rem',
          '--ListItem-paddingY': '0.6rem',
          '--ListItem-radius': '8px',
          '--ListItemDecorator-size': '32px',
        }}
      >
        <>{
          releases.map((release) => (
            <ListItem variant="outlined" key={release.name} sx={{ boxShadow: 'sm' }}>
              <RocketLaunchIcon/>
              <Radio
                overlay
                value={release.id}
                label={release.name}
                sx={{ flexGrow: 1, flexDirection: 'row-reverse', fontSize: 'smaller' }}
                checked={releaseSelection === release.id}
                onChange={() => {
                  setModelSelection(releases.find(r => r.id === release.id)?.models ?? [])
                  resetSearchKeyword()
                  setReleaseSelection(release.id)
                }}
                slotProps={{
                  input: { 'aria-describedby': `release-selector-${release.name}` },
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        inset: -1,
                        border: '2px solid',
                        borderColor: theme.vars.palette.primary[500],
                      }),
                    }),
                  }),
                }}
                checkedIcon={<CheckCircleRoundedIcon/>}
              />
              <FormHelperText id={`release-selector-${release.name}`} sx={{ margin: '0', fontSize: 'smaller' }}>
                {release.date}
              </FormHelperText>
            </ListItem>
          ))
        }</>
      </List>
    </RadioGroup>
  )
}
